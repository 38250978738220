import React from 'react'
import {NavigationContainer} from '@react-navigation/native'
import {createStackNavigator} from '@react-navigation/stack'
import LoginView from '../views/login'
import OrderView from '../views/order'
import OrderView1 from '../views/order1'
import PaymentView from '../views/payment'
import AddressView from '../views/address'
import MapView from '../views/webMap'
import SipProviderView from "../views/sipProviderView";

const Stack = createStackNavigator()

const route = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator headerMode={'none'} initialRouteName={'index'}>
                <Stack.Screen name='index' component={SipProviderView} />
                <Stack.Screen name='orderOld' component={OrderView} />
                <Stack.Screen name='order' component={OrderView1} />
                <Stack.Screen name='login' component={LoginView} />
                <Stack.Screen name='payment' component={PaymentView} />
                <Stack.Screen name='address' component={AddressView} />
                <Stack.Screen name='map' component={MapView} />
            </Stack.Navigator>
        </NavigationContainer>
    )
}

export default route