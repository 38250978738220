import cart from "../reducers/cart";

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_ITEM_QUANTITY = 'ADD_ITEM_QUANTITY';
export const REDUCE_ITEM_QUANTITY = 'REDUCE_ITEM_QUANTITY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_RESTAURANT_INFO_ID = 'UPDATE_RESTAURANT_INFO_ID';
export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO';
export const UPDATE_SCHEDULE_TIME = 'UPDATE_SCHEDULE_TIME';
export const UPDATE_TIPS = 'UPDATE_TIPS';
export const UPDATE_SPECIAL_INSTRUCTIONS = 'UPDATE_SPECIAL_INSTRUCTIONS';
export const UPDATE_DELIVERY_FEE = 'UPDATE_DELIVERY_FEE';

export const ADD_OPTION_QUANTITY = 'ADD_OPTION_QUANTITY';
export const REDUCE_OPTION_QUANTITY = 'REDUCE_OPTION_QUANTITY';
export const ADD_OPTION = 'ADD_OPTION';

export const EDIT_PRICE = 'EDIT_PRICE';


export const addToCart = item => {
    return { type: ADD_TO_CART, orderItem: item };
};

export const addItemQuantity = index => {
    return { type: ADD_ITEM_QUANTITY, index: index };
};

export const reduceItemQuantity = index => {
    return { type: REDUCE_ITEM_QUANTITY, index: index };
};

export const addOption = (index, option) => {
    return { type: ADD_OPTION, index: index, option: option };
};

export const editPrice = (index, price) => {
    return { type: EDIT_PRICE, index: index, price: price };
};

export const addOptionQuantity = index => {
    return { type: ADD_OPTION_QUANTITY, index: index };
};

export const reduceOptionQuantity = index => {
    return { type: REDUCE_OPTION_QUANTITY, index: index };
};


export const removeFromCart = index => {
    return { type: REMOVE_FROM_CART, index: index };
};

export const updateOrderType = orderType => {
    return { type: UPDATE_ORDER_TYPE, orderType: orderType };
};

export const clearItems = item => {
    return { type: UPDATE_ORDER };
};

export const updateRestaurantInfoId = id => {
    return { type: UPDATE_RESTAURANT_INFO_ID, restaurantInfoId: id };
}

export const updateCustomerInfo = customerInfo => {
    return { type: UPDATE_CUSTOMER_INFO, customerInfo: customerInfo };
}

export const updateScheduleTime = timeString => {
    return { type: UPDATE_SCHEDULE_TIME, scheduleTime: timeString };
}

export const updateTips = tips => {
    return { type: UPDATE_TIPS, tip: tips };
}

export const updateSpecialInstructions = specialInstructions => {
    return { type: UPDATE_SPECIAL_INSTRUCTIONS, specialInstructions: specialInstructions };
}
export const updateDeliveryFee = deliveryFee => {
    return { type: UPDATE_DELIVERY_FEE, deliveryFee: deliveryFee };
}
//partial adjustment: like scheduled time? updateCustomerInfo(changing phone number), tips(), specialInstruction.
//action type, 
//UpdateTheOrder? name
//displaying: showing the initial state
