import React, {useEffect, useState} from 'react'
import {Dimensions, Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {callPropType, sipPropType} from '@evercall/react-sip';
import * as PropTypes from "prop-types";
import * as cartAction from '../store/actions/cart';
import {useDispatch} from 'react-redux';
import Constant from "../until/constant";
import 'moment-timezone';
import moment from 'moment';
import { FontAwesome5 } from '@expo/vector-icons';

const Index = (props, context) => {
    const dispatch = useDispatch();
    const [restaurant, setRestuarant] = useState()
    const [previousRestaurant, setPreviousRestuarant] = useState()
    const [currentStoreName, setCurrentStoreName] = useState(null)
    const [currentStoreAddress, setCurrentStoreAddress] = useState('')
    const [currentStoreCityStateZip, setCurrentStoreCityStateZip] = useState('')
    const [currentCallReceived, setCurrentCallReceived] = useState(null)
    const [previousStoreName, setPreviousStoreName] = useState(null)
    const [previousStoreAddress, setPreviousStoreAddress] = useState('')
    const [previousStoreCityStateZip, setPreviousStoreCityStateZip] = useState('')
    const [previousCallReceived, setPreviousCallReceived] = useState(null)
    // console.log(context)
    const Sip = context;
    const fetchStore = async (restaurantPhone) => {
        // console.log(restaurantInfoId)
        await fetch(`${Constant.endPointUrl}/secure/api/tapthru/searchRestaurants?phoneNumber=${restaurantPhone}&includeHours=false`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                return response.json();
            })
            .then(responseText => {
                // You can do anything accroding to your API response
                //setStore(responseText[0]);
                setRestuarant(responseText[0])
            })
            .catch(error => {

            });
    }

    useEffect(() => {
        if (Sip.call.counterpart) {
            let callerId = Sip.call.counterpart.split('+')[1].split('@')[0];
            // let callerId = '19135583082'
            if (callerId.length > 10) {
                callerId = callerId.slice(1, 11);

                !restaurant && fetch(`${Constant.endPointUrl}/secure/api/tapthru/searchRestaurants?phoneNumber=${callerId}&includeHours=false`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(responseText => {
                        // You can do anything accroding to your API response
                        localStorage.storeinfo = JSON.stringify(responseText[0]);
                        !restaurant && setRestuarant(responseText[0])
                        !restaurant && setCurrentStoreName(responseText[0].name)
                        !restaurant && setCurrentStoreAddress(responseText[0].address)
                        !restaurant && setCurrentStoreCityStateZip(responseText[0].city + ", " + responseText[0].state + " " + responseText[0].zip)
                        setCurrentCallReceived(moment(new Date()).format("YYYY-MM-DD HH:mm:ss z"));
                    })
                    .catch(error => {

                    });
            }
            // if (!localStorage.call) {
            // setPhoneNumber(callerId);
            // }

            //localStorage.lastCallerId = callerId;
            // console.log(callerId);
        } else if (Sip.call.status === "callStatus/IDLE" && restaurant) {
            setPreviousRestuarant(restaurant);
            setPreviousStoreName(currentStoreName);
            setPreviousCallReceived(currentCallReceived);
            setPreviousStoreAddress(currentStoreAddress);
            setPreviousStoreCityStateZip(currentStoreCityStateZip);

            setRestuarant(null);
            setCurrentStoreName(null);
            setCurrentCallReceived(null);
            setCurrentStoreAddress(null);
            setCurrentStoreCityStateZip(null);
        }
    })

    return (
        <View style={styles.container}>
            <View style={{
                borderRadius: 10,
                borderColor: '#cdcdcd',
                borderWidth: 1,
                padding: 5
            }}>
                <View style={{width: 500}}>
                    <View style={{marginVertical: 5, paddingBottom: 5, flexDirection: 'row', alignContent: 'center', borderBottomColor: '#cdcdcd', borderBottomWidth: 1}}>
                        <View style={{flex: 2}}>
                            <Text style={{fontFamily: 'Regular', fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>Phone Status</Text>
                        </View>
                        <View style={{flex: 5, alignItems: 'stretch'}}>
                            <TouchableOpacity
                                onPress={() => {
                                    localStorage.showSipUserModal = true
                                    localStorage.removeItem("sipUser");
                                    document.location = "/";
                                }}
                            >
                                <Text style={{fontFamily: 'Regular', fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>
                                <FontAwesome5
                                    size={16}
                                    name={
                                        Sip.call.direction === "callDirection/INCOMING" && "phone-volume"
                                        || Sip.sip.status !== "sipStatus/ERROR" && Sip.sip.status !== "sipStatus/DISCONNECTED" && "phone"
                                        || "phone-slash"
                                    }
                                    color={
                                        Sip.sip.status === "sipStatus/CONNECTING" && "orange"
                                        || Sip.sip.status === "sipStatus/CONNECTED" && "lightgreen"
                                        || Sip.sip.status === "sipStatus/REGISTERED" && "green"
                                        || "red"
                                    }
                                />
                                    {Sip.sip.status === "sipStatus/REGISTERED" && Sip.sip.user && "    Log Out Ext. " + Sip.sip.user || "    Log In"}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{marginVertical: 5, flexDirection: 'row', alignContent: 'center'}}>
                        <View style={{flex: 2}}>
                            <Text style={{fontFamily: 'Regular', fontSize: 16, fontWeight: 'bold', textAlign: 'left'}}>Current</Text>
                        </View>
                        <View style={{flex: 5}}>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16,
                                marginBottom: 10
                            }}>
                                {currentCallReceived && currentCallReceived || Sip.sip.status === "sipStatus/REGISTERED" && 'Waiting for call' || "Not Logged In"}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {currentStoreName && currentStoreName || ' '}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {currentStoreAddress && currentStoreAddress || ' '}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {currentStoreCityStateZip && currentStoreCityStateZip || ' '}
                            </Text>
                        </View>
                    </View>
                    <View style={{paddingTop: 10, paddingBottom: 5, flexDirection: 'row', alignContent: 'flex-start', borderTopColor: '#cdcdcd', borderTopWidth: 1, borderBottomColor: '#cdcdcd', borderBottomWidth: 1 }}>
                        <View style={{flex: 2}}>
                            <Text style={{fontFamily: 'Regular', fontSize: 16, fontWeight: 'bold'}}>Previous</Text>
                        </View>
                        <View style={{flex: 5}}>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16,
                                marginBottom: 10
                            }}>
                                {previousCallReceived && previousCallReceived || ' '}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {previousStoreName && previousStoreName || ' '}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {previousStoreAddress && previousStoreAddress || ' '}
                            </Text>
                            <Text style={{
                                fontFamily: 'Regular',
                                fontSize: 16
                            }}>
                                {previousStoreCityStateZip && previousStoreCityStateZip || ' '}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{paddingTop: 10, paddingBottom: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                    <TouchableOpacity
                        style={[styles.orderType, { marginHorizontal: 10, opacity: restaurant || previousRestaurant? 1.0 : 0.5 }]}
                        disabled={!restaurant && !previousRestaurant}
                        onPress={() => {
                            dispatch(cartAction.updateOrderType('PICK_UP'));
                            if (restaurant) {
                                props.navigation.navigate('address', {restaurant: restaurant});
                            } else if (previousRestaurant) {
                                props.navigation.navigate('address', {restaurant: previousRestaurant});
                            }
                        }}
                    >
                        <View style={styles.iconStyle} >
                            <Image source={require('../assets/pick-up.png')} style={{ width: width < 1024 ? 18 : 23, height: width < 1024 ? 18 : 23 }} />
                        </View>
                        <Text style={styles.font}>Pick Up</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.orderType, { marginHorizontal: 10, opacity: restaurant || previousRestaurant? 1.0 : 0.5 }]}
                        disabled={!restaurant && !previousRestaurant}
                        onPress={() => {
                            dispatch(cartAction.updateOrderType('DELIVERY'));
                            if (restaurant) {
                                props.navigation.navigate('address', {restaurant: restaurant});
                            } else if (previousRestaurant) {
                                props.navigation.navigate('address', {restaurant: previousRestaurant});
                            }
                        }}
                    >
                        <View style={styles.iconStyle} >
                            <Image source={require('../assets/delivery.png')} style={{ width: width < 1024 ? 18 : 23, height: width < 1024 ? 18 : 23 }} />
                        </View>
                        <Text style={styles.font}>Delivery</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}
const { width, height } = Dimensions.get('window')

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    orderType: {
        backgroundColor: '#FFCC99',
        width: width < 1024 ? 75 : 85,
        height: width < 1024 ? 75 : 85,
        borderRadius: 10,
        margin: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    font: {
        fontFamily: 'Regular',
        fontSize: width < 1024 ? 14 : 16,
        marginTop: 5
    },
    iconStyle: {
        alignItems: 'center'
    }
})

Index.contextTypes = {
    sip: sipPropType,
    call: callPropType,
    registerSip: PropTypes.func,
    unregisterSip: PropTypes.func,

    answerCall: PropTypes.func,
    startCall: PropTypes.func,
    stopCall: PropTypes.func,
    sendKey: PropTypes.func
}

export default Index