import React, { useRef, useState, useEffect } from 'react'
import { View, StyleSheet, Text, Animated, TouchableOpacity, ScrollView, Dimensions, ActivityIndicator, FlatList, Platform, TextInput } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import { useSelector, useDispatch } from 'react-redux'
import { request } from '../until/request'
import { FormControl, Modal, Button } from 'react-bootstrap'
import * as cartAction from '../store/actions/cart';
import {opacity} from "react-native-reanimated/src/reanimated2/Colors";

// import appleMaps from '../until/appleMap' // 运行web端时，不注销，其他端口请注销

export default function pickUp(props) {
    const [stateActive, setStateActive] = useState(false)
    const stateDrop = useRef(new Animated.Value(0)).current
    const dropHight = stateDrop.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
    })
    const translate = useSelector(state => state.translate.translate)
    const [addressActive, setAddressActive] = useState(false)
    const [infoData, setInfoData] = useState({ phoneNumber: '', })
    const [search, setSearch] = useState(false)
    const [customerList, setCustomerList] = useState([])
    const dispatch = useDispatch();
    const [addressList, setAddressList] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [isAccept, setIsAccept] = useState(false)
    const [addressVerified, setAddressVerified] = useState(false)

    const stateList = [
        '',
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
        'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC',
        'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'PR'
    ]

    const order = JSON.parse(localStorage.localCart)

    useEffect(() => {
        var p = infoData.phoneNumber
        if (p.length === 10 && search === true) {

        }
    }, [infoData])

    const searchAddress = (text) => {
        setInfoData(pre => { return { ...pre, address: text } })
        if (text.length > 4) {
            appleMaps.autocomplete(text, (error, data) => {
                if (data.results) {
                    console.log(data.results)
                    var results = []
                    data.results.forEach(el => {
                        var p = {}
                        p.description = el.displayLines[0] + ' ' + el.displayLines[1]
                        results.push(p)
                    })
                    setAddressList(results)
                    setAddressActive(true)
                }
            })
        }
    }

    const searchFromPhone = (text) => {
        setInfoData(pre => { return { ...pre, phoneNumber: text } })
        if (text.length === 10) {
            var data = { phoneNumber: text, restaurantInfoId: 3 }
            request(`/secure/api/tapthru/getCustomers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `customerjson=${JSON.stringify(data)}`
            }).then(respone => respone.json())
                .then(responeText => {
                    if (responeText.length > 0) {
                        setCustomerList(responeText)
                        setInfoData(responeText[0])
                        setAddressActive(false)
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const saveCustomer = () => {
        // var data = infoData
        // if (order.orderType !== 'PICK_UP') {
        //     if (isAccept) {
        //         if (data.id) {
        //             request('/secure/api/tapthru/saveCustomer', {
        //                 method: 'POST',
        //                 headers: {
        //                     'Content-Type': 'application/x-www-form-urlencoded'
        //                 },
        //                 body: `customerjson=${JSON.stringify(data)}`
        //             }).then(respone => respone.json())
        //                 .then(responeText => {
        //                     dispatch(cartAction.updateCustomerInfo(data))
        //                     props.navigation.navigate('order', { restaurant: props.route.params.restaurant })
        //                 })
        //                 .catch(err => console.log(err))
        //         } else {
        //             data.id = null
        //             data.userId = null
        //             data.email = null
        //             data.distance = null
        //             data.customerProfileId = null
        //             data.customerPaymentProfiles = []
        //             data.name = ''
        //             data.restaurantInfoId = props.route.params.restaurant.id
        //             data.address2 = null
        //             request('/secure/api/tapthru/saveCustomer', {
        //                 method: 'POST',
        //                 headers: {
        //                     'Content-Type': 'application/x-www-form-urlencoded'
        //                 },
        //                 body: `customerjson=${JSON.stringify(data)}`
        //             }).then(respone => respone.json())
        //                 .then(responeText => {
        //                     responeText.id = null
        //                     dispatch(cartAction.updateCustomerInfo(responeText))
        //                     props.navigation.navigate('order', { restaurant: props.route.params.restaurant })
        //                 })
        //                 .catch(err => console.log(err))
        //         }
        //     } else {
        //         alert('please verify address !!!')
        //     }
        // } else {
        //     if (data.id) {
        //         request('/secure/api/tapthru/saveCustomer', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //             },
        //             body: `customerjson=${JSON.stringify(data)}`
        //         }).then(respone => respone.json())
        //             .then(responeText => {
        //                 dispatch(cartAction.updateCustomerInfo(data))
        //                 props.navigation.navigate('order', { restaurant: props.route.params.restaurant })
        //             })
        //             .catch(err => console.log(err))
        //     } else {
        //         data.id = null
        //         data.userId = null
        //         data.email = null
        //         data.distance = null
        //         data.customerProfileId = null
        //         data.customerPaymentProfiles = []
        //         data.name = ''
        //         data.restaurantInfoId = props.route.params.restaurant.id
        //         data.address2 = null
        //         request('/secure/api/tapthru/saveCustomer', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //             },
        //             body: `customerjson=${JSON.stringify(data)}`
        //         }).then(respone => respone.json())
        //             .then(responeText => {
        //                 dispatch(cartAction.updateCustomerInfo(responeText))
        //                 props.navigation.navigate('order', { restaurant: props.route.params.restaurant })
        //             })
        //             .catch(err => console.log(err))
        //     }
        // }

        dispatch(cartAction.updateCustomerInfo(infoData))
        let tpfRef = localStorage.tpfRef && JSON.parse(localStorage.tpfRef);

        if (tpfRef && tpfRef.tdf) {
            dispatch(cartAction.updateDeliveryFee(tpfRef.tdf))
        }
        props.navigation.navigate('order', { restaurant: props.route.params.restaurant })

    }

    const searchCustomer = () => {
        request(`/secure/api/tapthru/getCustomers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `customerjson=${JSON.stringify(infoData)}`
        }).then(response => response.json())
            .then(responeText => {
                if (responeText.length > 0) {
                    setSearch(false)
                    setCustomerList(responeText)
                    setInfoData(responeText[0])
                    setAddressActive(false)
                    setAddressVerified(false)
                }
            })
    }

    const verifyAddress = () => {
        setLoading(true)
        setShowModal(true)
        appleMaps.lookup(infoData.address, (error, detail) => {
            console.log(detail);
            if (!error && detail.results.length > 0) {
                const customer = {
                    address: detail.results[0].fullThoroughfare,
                    address2: null,
                    city: detail.results[0].locality,
                    state: detail.results[0].administrativeAreaCode,
                    zip: detail.results[0].postCode
                }
                setInfoData(pre => {
                    return {
                        ...pre,
                        address: customer.address,
                        address2: customer.address2 && customer.address2,
                        city: customer.city,
                        state: customer.state,
                        zip: customer.zip}
                });

                console.log(customer);
                setAddressVerified(true);

                let storeInfoDecoded = JSON.parse(localStorage.storeinfo);
                request(`/secure/api/tapthru/getDeliveryAvailability`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `customerjson=${JSON.stringify(customer)}&restaurantInfoId=${storeInfoDecoded.id}`
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(responseText => {
                        // You can do anything accroding to your API response
                        console.log(responseText);
                        setLoading(false)
                        if (responseText.available) {
                            setError('Verification successful!!!')
                            setIsAccept(true)
                            if (responseText.postmatesDeliveryFee) {
                                localStorage.tpfRef = `{"tdf": ${responseText.tapThruDeliveryFee}, "pdf": ${responseText.postmatesDeliveryFee}}`;
                            } else if (responseText.tapThruDeliveryFee) {
                                localStorage.tpfRef = `{"tdf": ${responseText.tapThruDeliveryFee}, "pdf": 0.00}`;
                            }
                        } else {
                            setError('Delivery out of range!!!')
                        }

                    })
                    .catch(error => {

                    });

            }
            else {
                setLoading(false)
                setError('Delivery out of range!!!')
                // setLoading(false)
                // setError({
                //     available: false,
                //     errorMessage: 'address is mistake!!'
                // })
            }
        })
    }
    return (
        <View style={styles.container} >
            {/* <ScrollView> */}
            <View style={{ justifyContent: 'center' }} >
                <Text style={{ fontFamily: 'Regular', fontWeight: 'bold', fontSize: 20, marginBottom: 10 }}>{order.orderType}</Text>
                <View style={{ flexDirection: 'row' }}>
                    <View>
                        {/* 表单 */}
                        <View style={{ borderWidth: 2, borderColor: 'rgb(217,217,217)', borderRadius: 5, paddingTop: 10, height: order.orderType === 'PICK_UP' ? 299 : 485 }} >
                            <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '手机号' : 'Phone'}#:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => searchFromPhone(text)} value={infoData.phoneNumber} />
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '???' : 'Ext'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => setInfoData(pre => { return { ...pre, phoneExt: text } })} value={infoData.phoneExt} />
                                </View>
                            </View>
                            {
                                order.orderType === 'PICK_UP' ?
                                    <></>
                                    :
                                    <View style={{ zIndex: 22 }} >
                                        <View style={{ zIndex: 22 }} >
                                            <View style={{ flexDirection: 'row', marginVertical: 5 }} >
                                                <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '地址' : 'Address'}:</Text></View>
                                                <TextInput
                                                    style={[styles.textInputStyle, { width: width < 1024 ? 415 : 595 }]}
                                                    onChangeText={text => {
                                                        searchAddress(text);
                                                        setAddressVerified(false);
                                                    }}
                                                    value={infoData.address} />
                                            </View>
                                            <View style={{ flexDirection: 'row', marginVertical: 5 }} >
                                                <View style={styles.textView} >
                                                    <Text style={styles.MeFont}>{translate ? '公寓/楼层/套房' : 'Apt/Floor/Suite'}:</Text>
                                                </View>
                                                <TextInput
                                                    style={[styles.textInputStyle, { width: width < 1024 ? 415 : 595 }]}
                                                    onChangeText={text => {
                                                        setInfoData((prevState => {
                                                            return {...prevState, address2: text.trim()};
                                                        }))
                                                    }}
                                                    value={infoData.address2} />
                                            </View>
                                            <View style={[styles.addressDrop, addressActive ? { position: 'absolute', display: 'flex' } : { position: 'relative', display: 'none' }]} >
                                                <ScrollView>
                                                    {
                                                        addressList.map((item, index) => {
                                                            return (
                                                                <TouchableOpacity key={index} onPress={() => { setAddressActive(false), setInfoData(preState => { return { ...preState, address: item.description } }) }} >
                                                                    <Text style={{ padding: 5 }} >{item.description}</Text>
                                                                </TouchableOpacity>
                                                            )
                                                        })
                                                    }
                                                </ScrollView>
                                                <View style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'center', paddingVertical: 5, paddingRight: 10, backgroundColor: '#fff' }} >
                                                    <Text style={{ fontWeight: 'bold', fontSize: 15 }} onPress={() => setAddressActive(false)} >Close</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View >
                                            <View style={{ flexDirection: 'row', marginVertical: 5 }} >
                                                <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '城市' : 'City'}:</Text></View>
                                                <TextInput
                                                    style={[styles.textInputStyle, {width: width < 1024 ? 150 : 190}]}
                                                    onChangeText={text => {
                                                        setInfoData(pre => {
                                                            return {...pre, city: text}
                                                        });
                                                        setAddressVerified(false);
                                                    }}
                                                    value={infoData.city}/>

                                                <View style={[styles.textView, { width: width < 1024 ? 50 : 60 }]} ><Text style={styles.MeFont}>{translate ? '州' : 'State'}:</Text></View>

                                                <FormControl
                                                    type='text'
                                                    as='select'
                                                    style={{
                                                        backgroundColor: 'rgb(247,247,247)',
                                                        borderWidth: 0,
                                                        paddingTop: 4,
                                                        paddingBottom: 4,
                                                        marginLeft: 3
                                                    }}
                                                    onChange={e => {
                                                        setInfoData(pre => {
                                                            return {...pre, state: e.target.value}
                                                        });
                                                        setAddressVerified(false);
                                                    }}
                                                    value={infoData.state}
                                                >
                                                    {
                                                        stateList.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item} >{item}</option>
                                                            )
                                                        })
                                                    }
                                                </FormControl>
                                                <View style={[styles.textView, { width: width < 1024 ? 40 : 50 }]} ><Text style={styles.MeFont}>{translate ? '邮政编码' : 'Zip'}:</Text></View>
                                                <TextInput
                                                    style={[styles.textInputStyle, { width: width < 1024 ? 90 : 120 }]}
                                                    onChangeText={text => {
                                                        setInfoData(pre => {
                                                            return {...pre, zip: text}
                                                        });
                                                        setAddressVerified(false);
                                                    }}
                                                    value={infoData.zip} />
                                            </View>
                                        </View>
                                    </View>
                            }


                            <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '姓' : 'First Name'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => setInfoData(pre => { return { ...pre, firstName: text } })} value={infoData.firstName} />
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '名' : 'Last Name'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => setInfoData(pre => { return { ...pre, lastName: text } })} value={infoData.lastName} />
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '地点' : 'Location'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => setInfoData(pre => { return { ...pre, location: text } })} value={infoData.lacation} />
                                </View>
                                <View style={{ flexDirection: 'row' }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '备注' : 'Remark'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle]} onChangeText={text => setInfoData(pre => { return { ...pre, remark: text } })} value={infoData.remark} />
                                </View>
                            </View>
                            <View>
                                <View style={{ flexDirection: 'row', marginVertical: 5 }} >
                                    <View style={styles.textView} ><Text style={styles.MeFont}>{translate ? '笔记' : 'Note'}:</Text></View>
                                    <TextInput style={[styles.textInputStyle, { width: width < 1024 ? 415 : 595 }]} onChangeText={text => setInfoData(pre => { return { ...pre, note: text } })} value={infoData.note} />
                                </View>
                            </View>


                            <View style={{ backgroundColor: '#fff', paddingVertical: 30, marginTop: 20 }} >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }} >
                                    <TouchableOpacity style={styles.auxiliaryButtonStyle} onPress={() => props.navigation.goBack()}>
                                        <Text style={[styles.Refont, { color: '#fff' }]} >{translate ? '取消' : 'Cancel'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.auxiliaryButtonStyle}>
                                        <Text style={[styles.Refont, { textAlign: 'center', color: '#fff' }]} >{translate ? '订单历史' : 'History'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.auxiliaryButtonStyle} onPress={searchCustomer}>
                                        <Text style={[styles.Refont, { color: '#fff' }]} >{translate ? '搜索' : 'Search'}</Text>
                                    </TouchableOpacity>
                                    {
                                        order.orderType === 'PICK_UP' ?
                                            <></>
                                            :
                                            <TouchableOpacity
                                                style={[styles.auxiliaryButtonStyle, {
                                                    opacity: infoData.address && infoData.address.trim().length > 0 ? 1.0 : 0.5
                                                }]}
                                                disabled={!infoData.address || infoData.address.trim().length === 0}
                                                onPress={verifyAddress}>
                                                <Text
                                                    style={[styles.Refont, {color: '#fff'}]}>{translate ? '验证' : 'Verify'}</Text>
                                            </TouchableOpacity>
                                    }
                                    <TouchableOpacity
                                        style={[
                                            styles.auxiliaryButtonStyle,
                                            {
                                                opacity: (order.orderType === 'PICK_UP' || (order.orderType === 'DELIVERY' && addressVerified)) ? 1.0 : 0.5
                                            }
                                        ]}
                                        disabled={!(order.orderType === 'PICK_UP'
                                            || (order.orderType === 'DELIVERY'
                                                && infoData.address
                                                && infoData.address.trim().length > 0
                                                && infoData.city
                                                && infoData.city.trim().length > 0
                                                && infoData.state
                                                && infoData.state.trim().length > 0
                                                && infoData.zip
                                                && infoData.zip.trim().length > 0
                                                && addressVerified))}
                                        onPress={saveCustomer} >
                                        <Text style={[styles.Refont, { color: '#fff' }]} >{translate ? '接受' : 'Accept'}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>


                        </View>
                    </View>
                    <View>
                        <View style={[styles.addressStyle, { height: order.orderType === 'PICK_UP' ? 299 : 485 }]} >
                            {
                                customerList.length === 0 ? <Text style={{ fontSize: 20, paddingHorizontal: 20 }} >No Address</Text>
                                    :
                                    <FlatList
                                        data={customerList}
                                        renderItem={({ item }) => {
                                            item.id = null;
                                            return (
                                                <TouchableOpacity
                                                    style={styles.addressDetailStyle}
                                                    onPress={() => {
                                                        setInfoData(item);
                                                        setAddressVerified(false);
                                                    }}>
                                                    <Text style={[styles.Refont, { textAlign: 'center', color: '#fff' }]}>{item.firstName}</Text>
                                                    <Text style={[styles.Refont, { textAlign: 'center', color: '#fff' }]}>{item.address ? item.address : 'null'}</Text>
                                                </TouchableOpacity>
                                            )
                                        }}
                                        numColumns={2}
                                        keyExtractor={(item, index) => index.toString()}
                                        showsHorizontalScrollIndicator={false}
                                    />
                            }

                        </View>
                    </View>
                </View>
            </View>
            <Modal show={showModal} centered onHide={() => { }}  >
                <Modal.Body>
                    {
                        loading ?
                            <View style={{ marginTop: 10, alignItems: 'center' }} >
                                <Text style={{ fontSize: 16, paddingVertical: 10 }} >Verifying Address.......</Text>
                                <ActivityIndicator size='large' />
                            </View>
                            :
                            <View style={{ marginTop: 10, alignItems: 'center' }}>
                                <Text style={{ fontSize: 16, paddingVertical: 10 }} >{error}</Text>
                                <TouchableOpacity style={{ backgroundColor: 'rgb(66, 133, 244)', width: '50%', alignItems: 'center' }} onPress={() => setShowModal(false)} >
                                    <Text style={{ color: '#fff', fontSize: 16, paddingVertical: 8, paddingHorizontal: 13 }} >OK</Text>
                                </TouchableOpacity>
                            </View>
                    }

                </Modal.Body>
            </Modal>
            {/* </ScrollView> */}
        </View>
    )
}
const { width } = Dimensions.get('window')
// rgb(66, 133, 244)
const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundColor: '#fff'
    },
    Refont: {
        fontFamily: 'Regular',
        fontSize: width < 1024 ? 13 : 13
    },
    MeFont: {
        fontFamily: 'TTFMedium',
        fontSize: width < 1024 ? 13 : 16
    },
    textView: {
        width: width < 1024 ? 70 : 140,
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    textInputStyle: {
        minHeight: width < 1024 ? 30 : 35,
        paddingLeft: 5,
        paddingVertical: 8,
        backgroundColor: 'rgb(247,247,247)',
        borderRadius: 5,
        marginRight: 10,
        marginLeft: 5,
        width: width < 1024 ? 170 : 220,
        fontSize: width < 1024 ? 13 : 16,
        fontFamily: 'Regular',
        borderColor: 'white',
        borderWidth: 2,
    },
    buttonStyle: {
        width: width < 1024 ? 60 : 70,
        height: width < 1024 ? 35 : 45,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFCC99',
        borderRadius: 2
    },
    auxiliaryButtonStyle: {
        // width: width < 1024 ? 70 : 77,
        // height: width < 1024 ? 45 : 50,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(66, 133, 244)',
        borderRadius: 100000,
        paddingHorizontal: 20,
        paddingVertical: 15
    },
    addressStyle: {
        // width: width < 1024 ? 203 : 223,
        height: width < 1024 ? 350 : 400,
        marginLeft: 15,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: 'rgb(217,217,217)',
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    addressDetailStyle: {
        width: width < 1024 ? 90 : 102,
        minHeight: 70,
        backgroundColor: 'rgb(66, 133, 244)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        margin: 5,
    },
    stateView: {
        width: width < 1024 ? 75 : 85,
        backgroundColor: 'white',
        position: 'absolute',
        top: width < 1024 ? 125 : 160,
        left: width < 1024 ? 292 : 337,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    optionStyle: {
        width: '100%',
        fontFamily: 'Regular',
        fontSize: width < 1024 ? 13 : 15,
        paddingHorizontal: 5,
        paddingVertical: 5,
        borderBottomWidth: 1,
        borderColor: 'rgb(200,200,200)',
        minHeight: width < 1024 ? 15 : 25
    },
    addressDrop: {
        width: width < 1024 ? 415 : 595,
        maxHeight: width < 1024 ? 100 : 150,
        backgroundColor: 'rgb(216,216,216)',
        top: width < 1024 ? 86 : 43,
        left: width < 1024 ? 85 : 147,
        borderColor: 'rgb(66, 133, 244)',
        borderWidth: 2,
        borderTopWidth: 0,
    }
})