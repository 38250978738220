import React from 'react'
import { View, Text } from 'react-native'

const Index = (props) => {

    return <View>
        <Text>登录</Text>
    </View>
}

export default Index