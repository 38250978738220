import {
    ADD_TO_CART,
    ADD_ITEM_QUANTITY,
    REDUCE_ITEM_QUANTITY,
    REMOVE_FROM_CART,
    UPDATE_ORDER_TYPE,
    UPDATE_ORDER,
    UPDATE_RESTAURANT_INFO_ID,
    UPDATE_CUSTOMER_INFO,
    UPDATE_SCHEDULE_TIME,
    UPDATE_TIPS,
    UPDATE_SPECIAL_INSTRUCTIONS,
    ADD_OPTION_QUANTITY,
    REDUCE_OPTION_QUANTITY,
    ADD_OPTION,
    EDIT_PRICE
} from '../actions/cart';


const initialState = localStorage.localCart ? JSON.parse(localStorage.localCart) : {
    restaurantInfoId: null,
    orderId: null,
    ticketNumber: null,
    online: true,
    customer: {
        restaurantInfoId: null,
        phoneNumber: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        firstName: null,
        lastName: null,
        email: null,
        userId: null,
        note: "Hand it to me",
        addressChanged: false
    },
    orderItems: [],
    orderType: 'PICK_UP',
    scheduleTime: null,
    subTotal: 0.00,
    total: 0.00,
    discount: 0.00,
    tip: 0.00,
    specialInstructions: '',
    deliveryFee: 0.00,
    convenienceFee: 1.25,
    mOrder: null,
    phoneOrder: true
};



const saveToLocalCartAndSetStateValue = (v) => {

    localStorage.localCart = JSON.stringify(v);
    return v;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:

            if (state.restaurantInfoId == null) {

                state.restaurantInfoId = action.orderItem.restaurantInfoId;
            }
            else {

                if (action.orderItem.restaurantInfoId != state.restaurantInfoId) {

                    state.orderItems = [];

                }
            }


            return saveToLocalCartAndSetStateValue({ ...state, restaurantInfoId: action.orderItem.restaurantInfoId, orderItems: state.orderItems.concat(action.orderItem) });

        case ADD_ITEM_QUANTITY:

            let itemsTobeAdded = state.orderItems;
            itemsTobeAdded[action.index.split(',')[0]].quantity = itemsTobeAdded[action.index.split(',')[0]].quantity + 1;

            return saveToLocalCartAndSetStateValue({ ...state, orderItems: itemsTobeAdded });

        case REDUCE_ITEM_QUANTITY:

            let itemsTobeReduced = state.orderItems;
            if (itemsTobeReduced[action.index.split(',')[0]].quantity > 1) {
                itemsTobeReduced[action.index.split(',')[0]].quantity = itemsTobeReduced[action.index.split(',')[0]].quantity - 1;
            } else {
                itemsTobeReduced.splice(action.index.split(',')[0], 1);
            }


            return saveToLocalCartAndSetStateValue({ ...state, orderItems: itemsTobeReduced });

        case ADD_OPTION:
            let tempItem = state.orderItems;
            if (tempItem[action.index.split(',')[0]]) {
                let options = tempItem[action.index.split(',')[0]].orderItemsOptions;
                tempItem[action.index.split(',')[0]] && options.splice(parseInt(action.index.split(',')[1]) + 1, 0, action.option)
                tempItem.orderItemsOptions = options;

                return saveToLocalCartAndSetStateValue({ ...state, orderItems: tempItem });
            } else {
                break
            }
        case ADD_OPTION_QUANTITY:
            const oai = action.index.split(',')[0];
            const oao = action.index.split(',')[1]
            let oa = state.orderItems;
            oa[oai].orderItemsOptions[oao].quantity = oa[oai].orderItemsOptions[oao].quantity + 1;
            return saveToLocalCartAndSetStateValue({ ...state, orderItems: oa });

        case REDUCE_OPTION_QUANTITY:


            let or = state.orderItems;
            const ori = action.index.split(',')[0];
            const oro = action.index.split(',')[1]
            if (or[ori].orderItemsOptions[oro].quantity > 1) {
                or[ori].orderItemsOptions[oro].quantity = or[ori].orderItemsOptions[oro].quantity - 1;
            } else {
                or[ori].orderItemsOptions.splice(oro, 1);
            }


            return saveToLocalCartAndSetStateValue({ ...state, orderItems: or });

        case EDIT_PRICE:

            let p = state.orderItems;
            console.log(p);
            if (action.index.split(',')[1] == '-1') {
                console.log('item');
                p[action.index.split(',')[0]].price = action.price;
            } else {
                console.log('option');
                console.log(p);
                p[action.index.split(',')[0]].orderItemsOptions[action.index.split(',')[1]].price = action.price;
                console.log(p);
            }


            return saveToLocalCartAndSetStateValue({ ...state, orderItems: p });

        case REMOVE_FROM_CART:

            let items = state.orderItems;

            items.splice(action.index, 1);



            return saveToLocalCartAndSetStateValue({ ...state, orderItems: items });


        case UPDATE_ORDER_TYPE:


            return saveToLocalCartAndSetStateValue({ ...state, orderType: action.orderType });

        case UPDATE_RESTAURANT_INFO_ID:


            return saveToLocalCartAndSetStateValue({ ...state, restaurantInfoId: action.restaurantInfoId });

        case UPDATE_CUSTOMER_INFO:

            return saveToLocalCartAndSetStateValue({ ...state, customer: action.customerInfo });

        case UPDATE_SCHEDULE_TIME:
            return saveToLocalCartAndSetStateValue({ ...state, scheduleTime: action.scheduleTime });


        case UPDATE_TIPS:
            console.log('tips update:' + action.tip)
            return saveToLocalCartAndSetStateValue({ ...state, tip: action.tip });

        case UPDATE_SPECIAL_INSTRUCTIONS:
            return saveToLocalCartAndSetStateValue({ ...state, specialInstructions: action.specialInstructions });


        case UPDATE_ORDER:
            return saveToLocalCartAndSetStateValue({ ...state, orderItems: [] });

    }
    return state;
};